<template>
  <div class="">
    <b-row class="product-statistics">
      <!--Time picker-->
      <b-col lg="6" sm="6">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <VueCtkDateTimePicker class="w-100" range
                                    v-model="dateFilter"
                                    :locale="currentFormattedLang" :only-date="true" :open.sync="dateFilterOpen"
                                    format="YYYY-MM-DD" formatted="YYYY-MM-DD" no-header no-value-to-custom-elem
              >
                <h3 class="mb-25 font-weight-bolder">
                  <b-link @click="dateFilterOpen = true">{{ dateFilterStr }}</b-link>
                </h3>
              </VueCtkDateTimePicker>
              <span>{{ msg('Period') }}</span>
            </div>
            <b-avatar variant="light-info" size="45"><i class="far fa-clock" style="font-size: 21px;"></i></b-avatar>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="6" sm="6">
        <statistic-card-horizontal icon="CreditCardIcon" :statistic-title="msg('CASH BALANCE')">
          <template v-slot:content>
            <div v-if="Array.isArray(monetaryData) && monetaryData.length>0">
              <span
                  style="font-size: 23px"> {{ getTotalBalance().toFixed(2) }} €</span>

            </div>

            <div v-else>
              <span
                  style="font-size: 23px"> 0.00 €</span>

            </div>
          </template>
        </statistic-card-horizontal>
      </b-col>
      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('Real estate')" label-for="realEstate-name">
          <real-estate-picker v-model="filterProperty"/>
        </b-form-group>
      </b-col>

      <b-col lg="6" sm="6">
        <b-card no-body class="overflow-hidden">
          <b-col>
            <b-card-body class="d-flex flex-column text-center">
              <h2 style="color: #00c853;font-weight: bold">{{ msg('INCOMES') }}</h2>
              <b-card-text style="font-size: large">
                <template v-if="Array.isArray(monetaryData) && monetaryData.length>0">
                  <span
                      style="font-size: 23px">{{ getDeposit().toFixed(2) }} EURO</span>

                </template>
                <template v-else>
              <span
                  style="font-size: 23px"> 0.00 €</span>

                </template>
              </b-card-text>
              <b-button class="mt-auto" href="#" variant="success" @click="monetaryClick('deposit')">
                {{ msg('New deposit') }}
              </b-button>
            </b-card-body>
          </b-col>
        </b-card>
      </b-col>
      <b-col lg="6" sm="6">
        <b-card no-body class="overflow-hidden">
          <b-col>
            <b-card-body class="d-flex flex-column text-center">
              <h2 style="color: red;font-weight: bold">{{ msg('OUTCOMES') }}</h2>
              <b-card-text style="font-size: large">
                <template v-if="Array.isArray(monetaryData) && monetaryData.length>0">
                  <span
                      style="font-size: 23px"> {{ getWithdraw().toFixed(2) }} EURO</span>

                </template>
                <template v-else>
              <span
                  style="font-size: 23px"> 0.00 €</span>

                </template>
              </b-card-text>

              <b-button class="mt-auto" href="#" variant="danger" @click="monetaryClick('withdraw')">
                {{ msg('New withdraw') }}
              </b-button>
            </b-card-body>

          </b-col>
        </b-card>
      </b-col>


    </b-row>

    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Cash Payments') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">

          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="amountPerPage"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="(amount)=>$refs['invoiceTable'].updatePageAmount(amount)"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
          </b-row>
          <cashbook-table ref="invoiceTable" @monetaryData="setData" v-bind:filter="invoiceFilter"/>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.monetary" size="lg" ref="monetary-modal" id="monetary-modal" :hide-footer="true"
             no-close-on-backdrop centered>
      <monetary-panel v-bind:type="typeSelected" ref="monetaryPanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitMonetary">
          {{ msg("Save") }}
        </b-button>
      </div>
    </b-modal>
  </div>

</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {mapActions, mapGetters} from 'vuex'
import CashbookTable from '@/views/monetary/invoice/cashbook-table'
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal";
import MonetaryPanel from './monetaryPanel'
import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";

export default {
  components: {
    RealEstatePicker,
    CashbookTable,
    MonetaryPanel,
    StatisticCardHorizontal,
    // eslint-disable-next-line vue/no-unused-components
    BCardActions
  },
  name: 'invoices',
  data: () => ({
    monetaryData: [],
    dialogs: {monetary: false},
    typeSelected: "",
    dateFilter: {},
    dateFilterOpen: false,
    filter: {

      fromDate: null,
      untilDate: null,
      in: {status: []},
      like: {id: "", amount: ""},
      gte: {creationDate: null,},
      lte: {creationDate: null}
    },
    amountPerPage: 10,
    items: [],
    filterProperty: null,
    //Listeners

    listeners: {
      setData: () => {
      }
    }

  }),
  created() {
    let $this = this

    this.$root.$on('crm::cashbook::monetaryData', $this.listeners.setData = (data) => {
      $this.setData(data)
    })
    this.dateFilter.start = this.$moment().startOf('month').format('YYYY-MM-DD');
    this.dateFilter.end = this.$moment().endOf('month').format('YYYY-MM-DD');
    this.dateFilter.shortcut = "thisMonth";
  },
  beforeDestroy() {
    this.$off('crm::cashbook::monetaryData', this.listeners.setData)
  },
  watch: {
   /* filterProperty: {
      refreshTable() {
        this.$refs.invoiceTable.refresh()
      }
    },*/
    filter: {
      deep: true,
      handler() {
      }
    },
  },
  computed: {
    ...mapGetters('order', ['allMonetaryStatus']),
    ...mapGetters('translator', ['msg']),
    dateShortcuts() {
      return [
        {
          key: 'thisWeek',
          label: 'This week',
          value: 'isoWeek'
        },
        {
          key: 'lastWeek',
          label: 'Last week',
          value: '-isoWeek'
        },
        {
          key: 'last7Days',
          label: 'Last 7 days',
          value: 7
        },
        {
          key: 'last30Days',
          label: 'Last 30 days',
          value: 30
        },
        {
          key: 'thisMonth',
          label: 'This month',
          value: 'month'
        },
        {
          key: 'lastMonth',
          label: 'Last month',
          value: '-month'
        },
        {
          key: 'thisYear',
          label: 'This year',
          value: 'year'
        },
        {
          key: 'lastYear',
          label: 'Last year',
          value: '-year'
        }
      ]
    },
    invoiceFilter() {
      let $this = this;
      let filter = {
        lte: {},
        gte: {},
        in: {method: ['DEPOSIT', 'WITHDRAW'], status: ['COMPLETED', 'PAID']},
        eq: {}
      };
      if ($this.dateFilter != null && $this.dateFilter.end != null) {
        filter.lte.creationDate = $this.dateFilter.end + " 00:00";
      }
      if ($this.dateFilter != null && $this.dateFilter.start != null) {
        filter.gte.creationDate = $this.dateFilter.start + " 00:00";
      }
      if ($this.filterProperty != null) {
        filter.eq.dynamicId2 = $this.filterProperty;
      }
      return filter;
    },
    dateFilterStr() {
      if (this.dateFilter.start == null) {
        return this.msg('Today')
      }
      let shortcut = this.dateShortcuts.find(s => this.dateFilter.shortcut === s.value)
      if (shortcut != null) {
        return shortcut.label
      }

      let str = this.$moment(this.dateFilter.start)
          .format('l')
      if (this.dateFilter.end == null) {
        return str
      }

      return str + ' - ' + this.$moment(this.dateFilter.end)
          .format('l')
    },
    orderStatus() {
      return this.allMonetaryStatus.map(status => ({
        value: status.value,
        text: this.msg(status.text)
      }))
    },
    creationDate: {
      get() {
        return {start: this.filter.fromDate, end: this.filter.untilDate}
      },
      set(v) {
        this.filter.fromDate = v.start;
        this.filter.untilDate = v.end;
      }
    }
  },
  methods: {
    monetaryClick(type) {
      this.typeSelected = type;
      this.dialogs.monetary = true;

    },
    getTotalBalance() {


      return (this.getWithdraw() + this.getDeposit());

    },
    getWithdraw() {
      if (this.monetaryData[0] != null && this.monetaryData[0].method === 'WITHDRAW')
        return this.monetaryData[0].amount;

      else if (this.monetaryData[1] != null && this.monetaryData[1].method === 'WITHDRAW')
        return this.monetaryData[1].amount;

      else return 0.0;

    },
    getDeposit() {
      if (this.monetaryData[0] != null && this.monetaryData[0].method === 'DEPOSIT')
        return this.monetaryData[0].amount;

      else if (this.monetaryData[1] != null && this.monetaryData[1].method === 'DEPOSIT')
        return this.monetaryData[1].amount;

      else return 0.0;

    },
    rangeToStr(dateRange) {
      if (dateRange.start == null)
        return "";
      if (dateRange.end == null)
        return this.$moment(dateRange.start, 'YYYY-MM-DD').format('DD/MM/YYYY');
      return this.$moment(dateRange.start, 'YYYY-MM-DD').format('DD/MM/YYYY') + " - " + this.$moment(dateRange.end, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    submitMonetary() {
      this.$root.$emit('crm::submitMonetary')
    },
    onSubmitted() {
      this.dialogs.monetary = false;
      this.$root.$emit('crm::cashbook-table::refresh')
    },
    setData(data) {
      console.log("data received: ", data)
      this.monetaryData = data;

    }
  }
}
</script>

<style scoped>

</style>
