<template>
  <div class="monetaryPanel">

    <div class="mt-2">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <template v-if="type==='deposit'">
            <div>
              <h4 style="color: green" class="mb-0 ml-50">
                <feather-icon icon="PlusIcon" size="19"/>
                <span> {{ msg('New Deposit') }}</span>
              </h4>
            </div>
          </template>
          <template v-else>
            <div>
              <h4 style="color: red" class="mb-0 ml-50">
                <feather-icon icon="MinusIcon" size="19"/>
                <span> {{ msg('New Withdraw') }}</span>
              </h4>
            </div>
          </template>
        </div>


        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">


          <!-- Field: First name -->
          <b-col cols="6" md="6" lg="6">
            <b-form-group :label="msg('Amount')+' (€)'" label-for="category-name">
              <validation-provider #default="{ errors }" :name="msg('Amount')" rules="required">
                <b-form-input id="category-name" v-model="monetary.amount" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Branch -->
          <b-col cols="6" md="6" lg="6">
            <b-form-group :label="msg('Branch')" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('Branch')" rules="required">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="monetary.buid"
                    :options="allBranches"
                    class="w-100"
                    label="name"
                    :reduce="(branch) => branch.id"
                    :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col cols="12" md="12" lg="12">
            <b-form-group :label="msg('Cause')" label-for="category-desc">
              <validation-provider #default="{ errors }" :name="msg('Cause')" rules="required">
                <b-form-input id="category-desc" v-model="monetary.comment"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>



          <b-col cols="12" md="12" lg="12">
            <b-form-group :label="msg('Real estate')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Property')" rules="required">
                <real-estate-picker v-model="monetary.dynamicId2"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </validation-observer>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import vue2Dropzone from "vue2-dropzone";
import PictureInput from 'vue-picture-input'
import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";

export default {
  name: 'monetaryPanel',
  components: {RealEstatePicker},
  data() {
    return {
      loaded: {page: false, upload: false},
      error: null,

      monetary: {
        amount: null,
        comment: null,
        method: "CASH"
      },

      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    type: {
      required: true,
    }
  },

  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  created() {
    this.$root.$on('crm::submitMonetary', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitMonetary', this.listeners.submit)
  },
  methods: {
    ...mapActions('monetary', ['getAllMonetary', 'getData', 'cashAdd', 'cashRemove']),


    validate(field) {
      return this.$v.monetary[field] != null && this.$v.monetary[field].$error === true
    },
    submit() {
      return this.createTransaction()
    },

    createTransaction() {
      let $this = this

      this.monetary.invoiceNumber="-"
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        if (this.type === 'deposit')
          this.cashAdd({monetary: $this.monetary})
              .then(() => $this.$emit("submitted"));
        else this.cashRemove({monetary: $this.monetary})
            .then(() => $this.$emit("submitted"));
      })
    },

  },

}
</script>


