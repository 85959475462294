var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"monetaryPanel"},[_c('div',{staticClass:"mt-2"},[_c('validation-observer',{ref:"refFormObserver"},[_c('div',{staticClass:"d-flex"},[(_vm.type==='deposit')?[_c('div',[_c('h4',{staticClass:"mb-0 ml-50",staticStyle:{"color":"green"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"19"}}),_c('span',[_vm._v(" "+_vm._s(_vm.msg('New Deposit')))])],1)])]:[_c('div',[_c('h4',{staticClass:"mb-0 ml-50",staticStyle:{"color":"red"}},[_c('feather-icon',{attrs:{"icon":"MinusIcon","size":"19"}}),_c('span',[_vm._v(" "+_vm._s(_vm.msg('New Withdraw')))])],1)])]],2),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Amount')+' (€)',"label-for":"category-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Amount'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"category-name","state":errors.length > 0 ? false:null},model:{value:(_vm.monetary.amount),callback:function ($$v) {_vm.$set(_vm.monetary, "amount", $$v)},expression:"monetary.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Branch'),"label-for":"product-branch"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Branch'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":_vm.allBranches,"label":"name","reduce":function (branch) { return branch.id; },"clearable":false},model:{value:(_vm.monetary.buid),callback:function ($$v) {_vm.$set(_vm.monetary, "buid", $$v)},expression:"monetary.buid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Cause'),"label-for":"category-desc"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Cause'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"category-desc"},model:{value:(_vm.monetary.comment),callback:function ($$v) {_vm.$set(_vm.monetary, "comment", $$v)},expression:"monetary.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Real estate'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Property'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('real-estate-picker',{model:{value:(_vm.monetary.dynamicId2),callback:function ($$v) {_vm.$set(_vm.monetary, "dynamicId2", $$v)},expression:"monetary.dynamicId2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }