<template>
  <div>
    <b-table
        ref="cashbook-table"
        striped
        hover
        responsive
        v-bind:items="invoices"
        v-bind:fields="fields"
        :busy="loading"
        @sort-changed="onSort"

    >

      <template #cell(clientId)="data">
        <div v-if="clients[data.value] != null">
          <b-link @click="onClientSelect(clients[data.value].id)">{{ clients[data.value].firstname }}
            {{ clients[data.value].lastname }}
          </b-link>
        </div>
        <div v-else-if="invoices[data.index].userId>0">
          <b-badge variant="info">{{ getUserFullName(invoices[data.index].userId) }}</b-badge>
        </div>
        <div v-else>
          <b-badge variant="primary">{{ msg('Client') }}</b-badge>
        </div>
      </template>

      <template #cell(creationDate)="data">
        {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
      </template>
      <template #cell(dynamicId2)="data">
       <span style="white-space: nowrap"> {{ data.value!=null ? propertyMap[data.value] : 'No property'}}</span>
      </template>

      <template #cell(buid)="data">
        {{ getBranchName(data.value) }}
      </template>


      <template #cell(amount)="data" class="p-0">
        <b-badge :variant="[data.value]>0?'success':'danger'">{{ data.value > 0 ? '+' : '' }}
          {{ (data.value).toFixed(2) }} {{ currencySymbol }}
        </b-badge>
      </template>

      <template #cell(status)="data" class="p-0">
        <b-badge :variant="statusVariants[data.value]">{{ data.value }}</b-badge>
      </template>
    </b-table>

    <div class="text-center w-100">
      <div class="d-inline-block">
        <b-pagination
            v-model="page.currentPage"
            :total-rows="page.total"
            :per-page="page.amount"
            first-number
            last-number
            @input="refresh"
        />
      </div>
      <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'cashbook-table',
  data() {
    return {
      loading: true,
      invoices: [],
      contractsMap: {},
      propertyMap: {},
      monetaryData: [],
      clients: {},
      sort: {by: 'creationDate', direction: 'desc',},
      page: {amount: 10, currentPage: 1, total: 0},
      statusVariants:
          {
            "NEW": "primary",
            "CASH_OPEN": "primary",
            "PENDING": "warning",
            "SHIPPED": "success",
            "EXPIRED": "danger",
            "PAID": "success",
            "CASH_TAKEN": "danger",
            "CASH_IN": "success",
            "PAID_CASH": "success",
            "WAITING_FOR_PAYMENT": "warning",
            "PAYMENT_FAILED": "danger",
            "CANCELLED": "danger",
            "COMPLETED": "success"
          },

      //Listeners
      listeners: {
        refresh: () => {
        }
      }
    }
  },
  props: {
    filter: {
      default: () => {
      },
    },
    fieldsFilter: {
      default: () => [],
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    }
  },
  created() {
    let $this = this
    this.getData(true)
    this.refresh()
    this.$root.$on('crm::cashbook-table::refresh', $this.listeners.refresh = () => {
      $this.refresh()
    })
    this.$root.$on('crm::branch-updated', $this.listeners.refresh)

  },
  beforeDestroy() {
    this.$off('crm::cashbook-table::refresh', this.listeners.refresh)
    this.$off('crm::branch-updated', this.listeners.refresh)
  },
  computed: {
    ...mapGetters('data', ['getBranchName']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),

    currencySymbol() {
      return '€';
    },
    fields() {
      let $this = this
      let fields = [
        {
          key: 'creationDate',
          label: $this.msg('CreationDate'),
          sortable: true
        },
        {
          key: 'amount',
          label: $this.msg('Total'),
          sortable: false
        },
        {
          key: 'clientId',
          label: $this.msg('User/Client'),
          sortable: false
        },

        {
          key: 'comment',
          label: $this.msg('Cause'),
          sortable: false
        },

        {
          key: 'status',
          label: this.msg('status'),
          sortable: true
        },

        {
          key: 'dynamicId2',
          label: this.msg('Property'),
          sortable: true
        },

        {
          key: 'buid',
          label: this.msg('Branch'),
          sortable: true
        },


      ]
      if (!Array.isArray(this.fieldsFilter) || this.fieldsFilter.length === 0) {
        return fields
      }
      return fields.filter(f => $this.fieldsFilter.includes(f.key))
    },
  },
  methods: {
    ...mapActions('monetary', ['getAllMonetary', 'getData']),
    ...mapActions('clients', ['getClients']),

    refresh() {
      this.loading = true;

      let $this = this
      this.getAllMonetary({
        page: this.page.currentPage,
        amountPerPage: this.page.amount,
        filter: this.filter,
        sortby: this.sort.direction,
        sortcolumn: this.sort.by
      }).then($this.onInvoicesRetrieve)
    },

    onInvoicesRetrieve({invoices, data, properties, contracts, amountPerPage, total, page}) {
      let $this = this

      this.invoices = invoices
      this.monetaryData = data
      this.contractsMap = contracts;
      this.propertyMap = properties;
      this.page.total = total;
      this.page.currentPage = page;
      this.page.amount = amountPerPage;
      this.retrieveClients(invoices);
      this.$root.$emit('crm::cashbook::monetaryData', data)
    },
    retrieveClients(invoices) {
      let $this = this
      let ids = invoices.map((invoice) => invoice.clientId)
      this.getClients({
        page: 1,
        amountPerPage: -1,
        filter: {ids}
      }).then((clientData) => {


        $this.clients = clientData.clients.toObject('id')
        $this.loading = false
      })
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', clientId)
    },
    onSort(data) {
      this.sort.by = data.sortBy
      this.sort.direction = data.sortDesc ? 'desc' : 'asc'
      this.refresh()
    },
    updatePageAmount(amount) {
      this.page.amount = amount;
      this.refresh();
    },
    onRowClick(invoice) {
      this.$router.push(`/report/monetary/invoice/${invoice.id}`)
    },
  }
}
</script>

<style scoped>

</style>
